<template>
  <div class="scanSheet">
    <ExamDetailsHeader
      ref="ExamDetailsHeader"
      router-url="examlist"
      :query="{ examId: $route.query.examId }"
      :name="examInformation.name + ' / '"
    >
    </ExamDetailsHeader>
    <div class="scan-sheet-box">
      <!-- 左边菜单盒子 -->
      <div class="scan-sheet-tab-box">
        <!-- 头部切换 -->
        <div class="tab-list">
          <div
            v-for="(v, i) in viewType"
            :key="i"
            :class="{ on: indexViewType == v.value }"
            @click="checkIndexView(v.value)"
          >
            {{ v.label }}
          </div>
        </div>
        <!-- scanTypeList -->
        <!-- 如果是扫描记录 显示 按班级  考场  批次 -->
        <div v-if="indexViewType == 2" class="scan-type-box">
          <div class="scan-tab-box">
            <div
              v-for="(v, i) in scanTypeList"
              :key="i"
              :class="{ on: v.value == scanType }"
              @click="checkList(v.value)"
            >
              {{ v.label }}
            </div>
          </div>
          <el-table
            v-if="scanType == 1"
            v-loading="tabLoading"
            :data="scanMessageList"
            style="width: 100%"
            border
            :row-class-name="tableRowClassName"
            @row-click="rowclick"
          >
            <template v-if="$route.query.examType == 1">
              <el-table-column prop="schoolName" label="学校" align="center">
              </el-table-column>
            </template>
            <template v-else>
              <el-table-column prop="classNum" label="班级" align="center">
              </el-table-column>
            </template>
            <el-table-column prop="planScans" label="计划人数" align="center">
            </el-table-column>
            <el-table-column
              prop="scanned"
              label="无卡人数"
              align="center"
              class="people-num"
            >
              <template slot-scope="scope">
                <span class="people-num">{{
                  scope.row.planScans - scope.row.scanned - scope.row.noScan
                }}</span></template
              >
            </el-table-column>
          </el-table>
          <el-table
            v-if="scanType == 2"
            v-loading="tabLoading"
            :data="scanMessageList"
            class="scan-batch"
            style="width: 100%"
            border
            :row-class-name="tableRowClassName"
            @row-click="rowclick"
          >
            <el-table-column prop="batch" label="批次" align="center">
              <template slot-scope="scope">
                <template v-if="!scope.row.batch"> - </template>
                <template v-else>
                  {{
                    scope.row.deviceId
                      ? scope.row.deviceId + "-" + scope.row.batch
                      : "" + (scope.row.deviceId ? "-" : "") + scope.row.batch
                  }}
                </template>
              </template>
            </el-table-column>
            <el-table-column
              prop="scanned"
              label="已扫"
              width="60"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="percentage"
              width="80"
              label="识别进度"
              align="center"
            >
            </el-table-column>
          </el-table>
          <el-table
            v-if="scanType == 3"
            v-loading="tabLoading"
            :data="scanMessageList"
            style="width: 100%"
            border
            :row-class-name="tableRowClassName"
            @row-click="rowclick"
          >
            <el-table-column prop="studentExamRoom" label="考场" align="center">
            </el-table-column>
            <el-table-column prop="planScans" label="计划人数" align="center">
            </el-table-column>
            <el-table-column prop="noScan" label="无卡人数" align="center">
              <template slot-scope="scope">
                <span class="people-num">{{
                  scope.row.planScans - scope.row.scanned - scope.row.noScan
                }}</span></template
              >
            </el-table-column>
          </el-table>
        </div>
        <div v-if="indexViewType == 1" class="paper-msg">
          <!-- <p class="paper-msg-title">{{ sheetInfomation.examName }}</p> -->
          <p class="paper-msg-list">
            <span>学段：</span>
            {{ examInformation.level | setName(levelOptions) }}
          </p>
          <p class="paper-msg-list">
            <span>年级：</span>{{ sheetInfomation.gradeName }}
          </p>
          <p class="paper-msg-list">
            <span>学科：</span>{{ sheetInfomation.subjectName }}
          </p>
          <p class="paper-msg-list">
            <span>学校：</span>{{ sheetInfomation.schoolName }}
          </p>
          <p
            v-if="sheetInfomation.templateVO"
            class="paper-msg-list"
            style="margin-bottom: 40px"
          >
            <span>模板：</span>{{ sheetInfomation.templateVO.templateName }}
          </p>

          <p class="paper-msg-list">
            <span>考生人数：</span> {{ sheetInfomation.examStudentNum }}
          </p>
          <p class="paper-msg-list">
            <span>正常卡人数：</span> {{ sheetInfomation.normalRecordNum }}
          </p>
          <p class="paper-msg-list">
            <span>缺考卡人数：</span> {{ sheetInfomation.missRecordNum }}
          </p>
          <p class="paper-msg-list" style="margin-bottom: 40px">
            <span>无卡人数：</span> {{ sheetInfomation.noRecordNum }}
          </p>
          <div v-if="sheetInfomation.templateVO">
            <p class="paper-msg-list">
              <span>纸张尺寸：</span>
              <template v-if="sheetInfomation.templateVO.paperType == 1"
                >A4</template
              >
              <template v-if="sheetInfomation.templateVO.paperType == 2"
                >A3</template
              >
              <template v-if="sheetInfomation.templateVO.paperType == 3"
                >B4</template
              >
            </p>
            <p class="paper-msg-list">
              <span>纸张样式：</span>
              <template v-if="sheetInfomation.templateVO.colorType == 1">
                黑卡
              </template>
              <template v-if="sheetInfomation.templateVO.colorType == 2">
                红卡
              </template>
            </p>
            <!-- colorType -->
            <p class="paper-msg-list">
              <span>试卷样式：</span>
              {{ sheetInfomation.templateVO.numberCount }}张{{
                sheetInfomation.templateVO.pageCount
              }}面
            </p>
            <p class="paper-msg-list">
              <span>客观题数：</span>
              {{ sheetInfomation.templateVO.objectiveNum }}
            </p>
            <p class="paper-msg-list">
              <span>主观题数：</span>
              {{ sheetInfomation.templateVO.subjectiveNum }}
            </p>
          </div>
        </div>
      </div>
      <clientSideInteraction
        v-show="indexViewType == 1"
        ref="clientSideInteraction"
        :sheet-infomation="sheetInfomation"
        :index-view-type="indexViewType"
        :paper-list="paperList"
        @checkTab="checkTab"
      ></clientSideInteraction>
      <sweepRecord
        v-show="indexViewType == 2"
        ref="sweepRecord"
        :scan-type="scanType"
        :index-view-type="indexViewType"
        :sheet-infomation="sheetInfomation"
        :exam-information="examInformation"
        :index-scan="indexScan"
        :scan-message-list="scanMessageList"
        @scanrecordbycondition="scanrecordbycondition"
        @getNewScanNum="getNewScanNum"
      ></sweepRecord>
    </div>
  </div>
</template>

<script>
const viewType = [
  {
    label: "扫描答卷",
    value: 1,
  },
  {
    label: "扫描记录",
    value: 2,
  },
];
const scanTypeList = [
  {
    label: "按班级查看",
    value: 1,
  },
  {
    label: "按考场查看",
    value: 3,
  },
  {
    label: "按批次查看",
    value: 2,
  },
];
import { levelOptions } from "@/core/util/constdata.js";
import ExamDetailsHeader from "@/components/examinationHeader";
import clientSideInteraction from "./components/clientSideInteraction";
import sweepRecord from "./components/sweepRecord";
import {
  scanrecordAnswersheet,
  scanrecordbycondition,
} from "@/core/api/exam/examScanSheet";
import { getObj } from "@/core/api/exam/exam";
export default {
  name: "ScanSheet",
  components: {
    ExamDetailsHeader,
    clientSideInteraction,
    sweepRecord,
  },
  data() {
    return {
      //扫描答卷  扫描记录
      viewType: viewType,
      indexViewType: 1,
      // 查看扫描类型
      scanTypeList: scanTypeList,
      scanType: 1,
      // 学段
      levelOptions: levelOptions(),
      // 从上一页传过来的基本信息
      examInformation: {
        name: "",
      },
      // 答题卡的基本信息
      sheetInfomation: {},
      scanMessageList: [],
      // 当前选中的子项
      indexScan: {},
      paperList: [],
      tabLoading: false,
    };
  },
  created() {
    this.scanrecordAnswersheet();
    this.getObj();
  },
  destroyed() {
    clearInterval(this.getSheetInfomation);
  },
  methods: {
    getNewScanNum() {
      this.scanrecordbycondition("getNewNum");
    },
    goBack() {
      this.$router.push({
        name: "examlist",
        query: { examId: this.$route.query.examId },
      });
    },
    checkSubject(v) {
      this.$route.query.examPaperId = v.value;
      this.indexPaperId = v.paperId;
      this.$router.push({
        query: {
          examPaperId: this.$route.query.examPaperId,
          examId: this.examInformation.examId,
          level: this.examInformation.level,
          examType: this.examInformation.examType,
          date: new Date().getTime(),
        },
      });
      this.$nextTick(() => {
        this.$refs.ExamDetailsHeader.init(this.$route.query.examPaperId);
      });
      this.scanrecordAnswersheet();
      this.scanrecordbycondition();
    },
    //获取考试基本信息
    getObj() {
      getObj(this.$route.query.examId).then((res) => {
        this.examInformation = res.data.data;

        this.paperList = res.data.data.paperList.map((item) => {
          if (item.statusSheet == 1) {
            item.label = item.subjectName;
            item.value = item.paperId;
            return item;
          } else {
            return null;
          }
        });
        this.paperList = this.paperList.filter((item) => item);
        this.indexPaperId = this.$route.query.examPaperId;
        this.$nextTick(() => {
          this.$refs.ExamDetailsHeader.init(this.$route.query.examPaperId);
        });
      });
    },
    checkTab() {
      this.indexViewType = 2;
      this.scanType = 2;
      this.indexScan = {};
      this.scanrecordbycondition();
    },
    tableRowClassName({ row }) {
      let str = "";
      if (row.planScans - row.scanned - row.noScan > 0) {
        str = " error-text";
      }

      if (this.indexScan == row) {
        return "index-row" + str;
      } else {
        return "" + str;
      }
    },
    rowclick(v) {
      this.indexScan = v;
      this.$nextTick(() => {
        this.$refs.sweepRecord.checkBatchList();
      });
    },
    scanrecordbycondition(val) {
      let data = {
        examPaperId: this.$route.query.examPaperId,
        type: this.scanType,
      };
      if (this.$route.query.examType == 1 && data.type == 1) {
        data.type = 4;
      }
      if (val != "getNewNum") {
        this.scanMessageList = [];
      }

      this.tabLoading = true;
      scanrecordbycondition(data).then((res) => {
        this.tabLoading = false;
        this.scanMessageList = res.data.data;
        // 加判断  核准无卡跳出当前页面
        if (val == "getNewNum") return;

        if (this.scanMessageList.length > 0) {
          if (!val) {
            this.indexScan = this.scanMessageList[0];
            if (this.indexViewType == 2) {
              this.$nextTick(() => {
                this.$refs.sweepRecord.checkBatchList();
              });
            }
          }
        } else {
          this.tabLoading = false;
          this.indexScan = {};
        }

        // console.log(res);
      });
    },
    checkList(val) {
      if (this.scanType == val) {
        return;
      }
      this.scanType = val;
      this.scanrecordbycondition();
    },
    checkIndexView(val) {
      this.indexViewType = val;
      this.scanrecordbycondition();
      if (this.indexViewType == 1) {
        this.$refs.clientSideInteraction.createWs();
      }
      // this.indexPaperId = val;
      // this.$nextTick(() => {
      //   this.$refs.ExamDetailsHeader.init(this.indexPaperId);
      // });
    },
    scanrecordAnswersheet(val) {
      let data = {
        examPaperId: this.$route.query.examPaperId,
      };
      if (this.$route.path != "/exam/scanSheet") return;
      scanrecordAnswersheet(data).then((res) => {
        this.sheetInfomation = res.data.data;

        if (val) {
          if (this.indexViewType == 1) {
            this.getSheetInfomation = setTimeout(() => {
              this.scanrecordAnswersheet(1);
            }, 10000);
          } else {
            // this.$refs.sweepRecord.loadView();
            clearInterval(this.getSheetInfomation);
          }
          return;
        }
        // console.log(val);
        this.$nextTick(() => {
          if (this.indexViewType == 1) {
            this.getSheetInfomation = setTimeout(() => {
              this.scanrecordAnswersheet(1);
            }, 10000);
            this.$refs.clientSideInteraction.createWs();
          } else {
            this.$refs.sweepRecord.loadView();
            clearInterval(this.getSheetInfomation);
          }
        });
      });
    },
    pushMsg() {},
  },
};
</script>
<style lang="scss" scoped>
.scanSheet {
  .scan-batch {
    ::v-deep .cell {
      padding-left: 6px;
      padding-right: 6px;
    }
  }
  padding: 24px 0 64px;
  .el-dropdown-link {
    cursor: pointer;
    color: $primary-color;
    font-size: 16px;
  }
  .header-box {
    background-color: #ffffff;
    padding: 24px 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .el-select {
      width: 112px;
      height: 32px;
    }
    .el-input {
      width: 192px;
    }
    .header-search-box {
      font-weight: 400;
      display: flex;
      flex-shrink: 0;
      flex-wrap: wrap;
      .search-item {
        font-size: 14px;
        margin-right: 24px;
        margin-bottom: 24px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .btn-list {
      margin-bottom: 24px;
    }
    .title {
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 24px;
      color: #080a09;
      // width: 100%;
      .el-icon-arrow-left {
        cursor: pointer;
      }
    }
  }
  ::v-deep .el-table__row {
    cursor: pointer;
  }
  ::v-deep .error-text {
    .people-num {
      color: red;
    }
  }
  ::v-deep .index-row {
    background-color: #f6fdff;
    color: $primary-color;

    td {
      &:first-child {
        position: relative;
        &::after {
          position: absolute;
          content: "";
          left: 0;
          top: 0;
          width: 2px;
          height: 100%;
          background-color: $primary-color;
        }
      }
    }
  }
  .scan-sheet-box {
    display: flex;
    align-items: flex-start;
    margin-top: 24px;

    .scan-sheet-tab-box {
      // margin-top: 24px;
      margin-right: 24px;
      flex-shrink: 0;
      background: #ffffff;
      width: 300px;
      .paper-msg {
        padding: 24px;
        color: #2f3130;
        .paper-msg-title {
          font-weight: 500;
          margin-bottom: 30px;
        }
        .sheet-box {
          padding: 24px;
          background: #fbfbfb;
        }
        .paper-msg-list {
          margin-bottom: 12px;
          span {
            color: #606266;
          }
        }
      }
      .scan-type-box {
        padding: 24px 18px;
        .scan-tab-box {
          display: flex;
          border-radius: 4px;
          margin-bottom: 24px;
          .on {
            background-color: $primary-color;
            color: #ffffff;
          }
          div {
            border: 1px solid #d9d9d9;
            padding: 7px 5px;
            width: 33.33%;
            cursor: pointer;
            &:first-child {
              border-right: none;
            }
            &:last-child {
              border-left: none;
            }
          }
        }
      }
      .tab-list {
        display: flex;
        border-bottom: 1px solid #d8d8d8;
        text-align: center;
        justify-content: space-between;

        div {
          width: 50%;
          position: relative;
          padding: 24px 0;
          cursor: pointer;
        }
        .on {
          color: $primary-color;
          &::after {
            content: "";
            width: 40px;
            height: 2px;
            background-color: $primary-color;
            position: absolute;
            left: 50%;
            bottom: 0px;
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            -o-transform: translateX(-50%);
          }
        }
      }
    }
  }
}
</style>
