<template>
  <div class="sweepRecord">
    <div class="sweep-record-header">
      <div class="sweep-record-header-item">
        <!-- <div>{{ sheetInfomation.examName }}</div> -->
        <div>学段：{{ examInformation.level | setName(levelOptions) }}</div>
        <div>年级：{{ sheetInfomation.gradeName }}</div>
        <div>学科：{{ sheetInfomation.subjectName }}</div>
        <!-- <div>学校：{{ sheetInfomation.schoolName }}</div> -->
      </div>
      <div class="sweep-record-header-item">
        <div>考生人数：{{ scanrecordData.examStudentNum }}</div>
        <div>正常卡人数：{{ scanrecordData.normalRecordNum }}</div>
        <div>缺考卡人数：{{ scanrecordData.missRecordNum }}</div>
        <div>
          无卡人数：{{ scanrecordData.noRecordNum }}（已核准{{
            scanrecordData.confirmMissRecordNum || 0
          }}张）
        </div>
      </div>
    </div>
    <div class="search-list">
      <div class="tab-list">
        <template v-if="scanType == 1 || scanType == 3">
          <div
            v-for="(v, i) in scanStateType"
            :key="i"
            :class="{ on: scanState == v.value }"
            @click="checkState(v.value)"
          >
            {{ v.lable }}
          </div>
        </template>
      </div>
      <div>
        <el-input
          v-model="keyword"
          placeholder="考生姓名/考号"
          class="input-with-select"
          @keyup.enter.native="searchList"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            :loading="tabLoading"
            @click="searchList()"
          ></el-button>
        </el-input>

        <el-dropdown v-if="scanType == 2" style="margin-right: 12px">
          <el-button type="primary" plain>
            重新识别 <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="checkSheetStatus(0)">
              按选中答题卡
            </el-dropdown-item>
            <el-dropdown-item @click.native="checkSheetStatus(1)"
              >按扫描批次</el-dropdown-item
            >
            <el-dropdown-item @click.native="checkSheetStatus(2)"
              >按纸张状态</el-dropdown-item
            >
            <el-dropdown-item @click.native="checkSheetStatus(3)"
              >所有答题卡</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <el-button
          v-if="scanType == 2"
          type="primary"
          plain
          @click="setParameter()"
          >设置识别参数</el-button
        >
        <el-button
          v-if="scanType == 2 || (scanType != 2 && scanState != 2)"
          type="primary"
          plain
          @click="delList"
          >批量删除</el-button
        >
        <el-button
          v-if="scanType != 2 && scanState == 2"
          type="primary"
          plain
          @click="setAll(1)"
          >批量设为无卡</el-button
        >

        <el-button
          v-if="scanType != 2 && scanState == 2"
          type="primary"
          plain
          @click="setAll(0)"
          >批量取消无卡</el-button
        >

        <el-button
          v-if="scanType != 2"
          type="primary"
          plain
          :loading="btnLoading"
          @click="downloadScanrecord"
          >导出</el-button
        >
        <el-button
          v-if="(scanType != 2 && scanState != 2) || scanType == 2"
          type="primary"
          plain
          @click="showClear()"
          >清空答题卡</el-button
        >
      </div>
    </div>
    <div class="table-box">
      <el-table
        v-if="scanType == 1"
        :key="tabKey"
        v-loading="tabLoading"
        :data="studentScanList"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <!-- <el-table-column type="index" label="序号" width="80px" align="center">
        </el-table-column> -->
        <template v-if="$route.query.examType == 1">
          <el-table-column prop="schoolName" label="学校" align="center">
          </el-table-column>
        </template>
        <el-table-column prop="classNum" label="班级" align="center">
        </el-table-column>
        <el-table-column prop="name" label="姓名" align="center">
        </el-table-column>
        <el-table-column prop="studentExamId" label="考号" align="center">
        </el-table-column>
        <el-table-column prop="missExam" label="调整" align="center">
          <!--   scanState-->
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.url"
              type="text"
              @click="adjust(scope.row)"
            >
              调整
            </el-button>
            <template v-else>-</template>
          </template>
        </el-table-column>
        <el-table-column prop="missExam" label="替换" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.url"
              type="text"
              @click="replace(scope.row)"
            >
              替换
            </el-button>
            <template v-else>-</template>
          </template>
        </el-table-column>
        <el-table-column
          v-if="scanState != 2"
          prop="studentExamId"
          label="退回"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="gobackSheet(scope.row)">
              退回
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="missExam" label="缺考设置" align="center">
          <template slot-scope="scope">
            <!-- <template v-if="scope.row.missExam == 0">-</template> -->
            <el-button
              v-if="scope.row.missExam != 3"
              type="text"
              @click="setMiss(1, scope.row)"
            >
              设置
            </el-button>
            <el-button
              v-if="scope.row.missExam == 3"
              type="text"
              class="err"
              @click="setMiss(0, scope.row)"
            >
              取消
            </el-button>
            <!-- <template v-if="scope.row.missExam == 1">待确认</template>
            <template v-if="scope.row.missExam == 2">确认未缺考</template>
            <template v-if="scope.row.missExam == 3">确认缺考</template> -->
          </template>
        </el-table-column>

        <!-- <el-table-column prop="address" label="扫描状态" align="center">
          <template slot-scope="scope">
            <template v-if="scope.row.status"> 已扫描 </template>
            <template v-else> 未扫描 </template>
          </template>
        </el-table-column> -->
        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="scanState == 1 || scanState == 3"
              type="text"
              @click="showPaper(scope.row)"
              >查看原卷</el-button
            >
            <template v-else>
              <el-button
                v-if="scope.row.absent == 0"
                type="text"
                class="err"
                @click="setAbsent(scope.row, 1)"
              >
                核准无卡
              </el-button>
              <el-button v-else type="text" @click="setAbsent(scope.row, 0)">
                已核准无卡
              </el-button>
            </template>

            <!-- absent -->
          </template>
        </el-table-column>
      </el-table>
      <el-table
        v-if="scanType == 2"
        :key="tabKey"
        v-loading="tabLoading"
        :data="batchScanList"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          prop="batch"
          label="批次"
          align="center"
          column-key="batch"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.deviceId">
              {{ scope.row.deviceId + "-" + scope.row.batch }}
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="classNum" label="班级" align="center">
        </el-table-column>

        <el-table-column prop="name" label="姓名" align="center">
        </el-table-column>
        <el-table-column prop="studentExamId" label="考号" align="center">
        </el-table-column>

        <el-table-column prop="sequenceOrder" label="进纸顺序" align="center">
        </el-table-column>
        <!-- <el-table-column prop="missExam" label="调整" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.url"
              type="text"
              @click="adjust(scope.row)"
            >
              调整
            </el-button>
            <template v-else>-</template>
          </template>
        </el-table-column> -->
        <el-table-column
          prop="scanTime"
          :show-overflow-tooltip="true"
          label="扫描时间"
          align="center"
          width="220"
        >
        </el-table-column>
        <el-table-column
          prop="address"
          label="扫描状态"
          align="center"
          column-key="batch"
          width="160"
          :show-overflow-tooltip="true"
        >
          <template #header>
            <el-popover v-model="visible" placement="bottom" width="160">
              <div>
                <div class="tooltip-btn-list">
                  <el-button type="text" @click="setErrList(1)">全选</el-button>
                  <el-button type="text" @click="setErrList(2)">反选</el-button>
                  <el-button type="text" @click="setErrList(3)">取消</el-button>
                </div>

                <el-checkbox-group v-model="errChange">
                  <!-- <el-checkbox :label="-1"> 全部纸张状态 </el-checkbox> -->
                  <el-checkbox
                    v-for="(v, i) in errTypeList"
                    :key="i"
                    :label="v.value"
                  >
                    {{ v.label }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
              <span slot="reference">
                请选择纸张状态 <i class="el-icon-arrow-down"></i
              ></span>
            </el-popover>
          </template>
          <template slot-scope="scope">
            <template v-if="scope.row.errType">
              {{ scope.row.errType.split(",")[0] | setName(errTypeList) }}
            </template>
            <template v-else> 正常 </template>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="studentExamId" label="退回" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="gobackSheet(scope.row)">
              退回
            </el-button>
          </template>
        </el-table-column> -->
        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.errType != 8"
              type="text"
              @click="showPaper(scope.row)"
            >
              查看原卷
            </el-button>
            <template v-else>-</template>
          </template>
        </el-table-column>
      </el-table>
      <el-table
        v-if="scanType == 3"
        :key="tabKey"
        v-loading="tabLoading"
        :data="roomScanList"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          prop="studentExamRoom"
          label="考场"
          align="center"
          :show-overflow-tooltip="true"
        >
          <!--      column-key="batch" -->
          <!-- <template #header>
            <el-select
              v-model="typeChange"
              class="select-style"
              placeholder="请选择"
              @change="filterChange"
            >
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template> -->
        </el-table-column>
        <el-table-column prop="classNum" label="班级" align="center">
        </el-table-column>
        <el-table-column prop="name" label="姓名" align="center">
        </el-table-column>
        <el-table-column prop="studentExamId" label="考号" align="center">
        </el-table-column>
        <el-table-column prop="missExam" label="调整" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.url"
              type="text"
              @click="adjust(scope.row)"
            >
              调整
            </el-button>
            <template v-else>-</template>
          </template>
        </el-table-column>
        <el-table-column prop="missExam" label="替换" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.url"
              type="text"
              @click="replace(scope.row)"
            >
              替换
            </el-button>
            <template v-else>-</template>
          </template>
        </el-table-column>
        <el-table-column
          v-if="scanState != 2"
          prop="studentExamId"
          label="退回"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="gobackSheet(scope.row)">
              退回
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="missExam" label="是否缺考" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.missExam != 3"
              type="text"
              @click="setMiss(1, scope.row)"
            >
              设置
            </el-button>
            <el-button
              v-if="scope.row.missExam == 3"
              type="text"
              class="err"
              @click="setMiss(0, scope.row)"
            >
              取消
            </el-button>
          </template>
        </el-table-column>

        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <template v-if="scanState == 1 || scanState == 3">
              <el-button
                v-if="scope.row.errType != 8"
                type="text"
                @click="showPaper(scope.row)"
              >
                查看原卷
              </el-button>
              <template v-else>-</template>
            </template>
            <template v-else>
              <el-button
                v-if="scope.row.absent == 0"
                type="text"
                class="err"
                @click="setAbsent(scope.row, 1)"
              >
                核准无卡
              </el-button>
              <el-button v-else type="text" @click="setAbsent(scope.row, 0)">
                已核准无卡
              </el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPage"
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
      />
    </div>
    <!-- <el-dialog title="原卷" :visible.sync="dialogVisible" width="70%">
      <div class="model-img">
        <img v-for="(v, i) in imgUrl" :key="i" :src="v" alt="" />
      </div>
    </el-dialog> -->

    <el-dialog
      title="清空答题卡"
      :visible.sync="showdelTsModel"
      width="600px"
      class="ts-model"
    >
      <div style="margin-bottom: 8px">
        <span v-if="scanType == 1"> 请选择需要清空的班级 </span>
        <span v-if="scanType == 2"> 请选择需要清空的批次 </span>
        <span v-if="scanType == 3"> 请选择需要清空的考场 </span>

        <el-button type="text" @click="checkBtn(1)">全选</el-button>
        <el-button type="text" @click="checkBtn(2)">反选</el-button>
        <el-button type="text" @click="checkBtn(3)">取消</el-button>
      </div>

      <el-checkbox-group v-model="checkSheet">
        <template v-if="scanType == 2">
          <template v-for="(v, i) in scanMessageList">
            <el-checkbox
              v-if="v.batch != '全部'"
              :key="i"
              :label="
                v.deviceId
                  ? v.deviceId + '-' + v.batch
                  : '' + (v.deviceId ? '-' : '') + v.batch
              "
            >
              {{
                v.deviceId
                  ? v.deviceId + "-" + v.batch
                  : "" + (v.deviceId ? "-" : "") + v.batch
              }}
            </el-checkbox>
          </template>
        </template>
        <template v-if="scanType == 1">
          <template v-for="(v, i) in scanMessageList">
            <el-checkbox
              v-if="v.classNum != '全部'"
              :key="i"
              :label="v.classNum"
            >
              {{ v.classNum }}
            </el-checkbox>
          </template>
        </template>
        <template v-if="scanType == 3">
          <template v-for="(v, i) in scanMessageList">
            <el-checkbox
              v-if="v.studentExamRoom != '全部'"
              :key="i"
              :label="v.studentExamRoom"
            >
              {{ v.studentExamRoom || "空" }}
            </el-checkbox>
          </template>
        </template>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showdelTsModel = false">取 消</el-button>
        <el-button type="primary" @click="showClearPass"> 确 定 </el-button>
      </span>
    </el-dialog>
    <el-dialog title="清空答题卡" :visible.sync="clearPassword" width="400px">
      <div>
        此操作将永久清空<template v-if="scanType == 1"> 班级 </template>
        <template v-if="scanType == 2"> 批次 </template>
        <template v-if="scanType == 3"> 考场 </template>
        <span class="err-tips">{{ checkSheet.join(",") }}</span>
        并且会
        <span class="err-tips"
          >清空选中数据考生成绩，阅卷记录等，属于高危操作</span
        >, 是否继续?
      </div>
      <div class="password">
        <span>请输入登录密码：</span>
        <el-input
          v-model="password"
          autocomplete="new-password"
          placeholder="请输入登录密码"
          show-password
          @keyup.enter.native="clearSheet()"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="(clearPassword = false) && (showdelTsModel = true)"
          >取 消</el-button
        >
        <el-button
          type="primary"
          :loading="delBtnLoading"
          @click="clearSheet()"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
    <el-dialog title="温馨提示" :visible.sync="delPassword" width="400px">
      <div>
        此操作将永久删除共
        <span class="err-tips">{{ multipleSelection.length }}</span>
        张试卷,并且会
        <span class="err-tips">清空选中考生成绩，阅卷记录等，属于高危操作</span
        >, 是否继续?
      </div>
      <div class="password">
        <span>请输入登录密码：</span>
        <el-input
          v-model="password"
          autocomplete="new-password"
          placeholder="请输入登录密码"
          show-password
          @keyup.enter.native="monitorBack"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="(delPassword = false) && (multipleSelection = [])"
          >取 消</el-button
        >
        <el-button
          type="primary"
          :loading="delBtnLoading"
          @click="monitorBack()"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
    <showImgList ref="showImgList" :src-list="imgUrl"></showImgList>
    <IdentificationParameter
      ref="IdentificationParameter"
    ></IdentificationParameter>
    <reidentificationSheet ref="reidentificationSheet"></reidentificationSheet>
  </div>
</template>

<script>
const scanStateType = [
  {
    value: 1,
    lable: "正常卡",
  },
  {
    value: 3,
    lable: "缺考卡",
  },
  {
    value: 2,
    lable: "无卡",
  },
];

import { levelOptions, errTypeList } from "@/core/util/constdata.js";
import {
  getScanrecordclass,
  scanrecordbatch,
  scanrecordexamroom,
  deleteScanrecord,
  scanrecordAnswersheet,
  downloadScanrecord,
  approvalAbsent,
  identifyagainBatch,
  scanrecordschool,
  scanrecordAbsent,
  scanrecordBack,
  deleteScanrecordAll,
} from "@/core/api/exam/examScanSheet";
import { getStore } from "@/core/util/store";
import { fileDownloadByUrl, encryption } from "@/core/util/util";
import showImgList from "@/components/showImgList";
import IdentificationParameter from "@/components/scan/IdentificationParameter";
import reidentificationSheet from "@/components/scan/reidentificationSheet";
export default {
  name: "SweepRecord",
  components: { showImgList, IdentificationParameter, reidentificationSheet },
  props: {
    // scanType: Number,
    scanType: {
      type: Number,
      default: () => {},
    },
    indexViewType: {
      type: Number,
      default: () => {},
    },
    sheetInfomation: {
      type: Object,
      default: () => {},
    },
    examInformation: {
      type: Object,
      default: () => {},
    },
    indexScan: {
      type: Object,
      default: () => {},
    },
    scanMessageList: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      delPassword: false,
      clearPassword: false,
      showdelTsModel: false,
      checkSheet: [],
      password: "",
      scanrecordData: {},
      tabLoading: false,
      // 试卷弹窗
      btnLoading: false,
      delBtnLoading: false,
      dialogVisible: false,
      levelOptions: levelOptions(),
      errTypeList: errTypeList,
      scanStateType: scanStateType,
      scanState: 1,
      tabKey: 1,
      keyword: "",
      // 学生扫描信息
      studentScanList: [],
      batchScanList: [],
      roomScanList: [],
      pageIndex: 1,
      pageSize: 50,
      totalPage: 0,
      multipleSelection: [],
      typeList: [],
      imgUrl: [],
      errChange: [],
      typeChange: "",
      visible: false,
      // Scanrecordclass
    };
  },
  watch: {
    visible() {
      // console.log(this.visible);
      if (!this.visible) {
        this.filterChange();
      }
    },
    indexViewType() {
      if (this.indexViewType == 2) {
        this.scanrecordAnswersheet();
      } else {
        clearTimeout(this.getInfo);
      }
    },
    scanMessageList: {
      deep: true,
      handler() {
        // if (this.scanType == 2) {
        this.setbatchList();
        // }
        // console.log();
      },
    },
  },
  created() {
    if (this.scanType == 1) {
      if (this.$route.query.examType == 1) {
        this.scanrecordschool();
      } else {
        this.getScanrecordclass();
      }
    }
    if (this.scanType == 2) {
      this.scanrecordbatch();
    }
    if (this.scanType == 3) {
      this.scanrecordexamroom();
    }
    this.setbatchList();
    clearTimeout(this.getInfo);
    this.scanrecordAnswersheet();
    this.userInfo = getStore({ name: "userInfo" });
  },
  destroyed() {
    clearTimeout(this.getInfo);
  },
  methods: {
    replace(item) {
      // console.log();
      let sheetInfomation = Object.assign(
        {},

        this.sheetInfomation,
        this.$route.query,
        item
      );
      let Base64 = require("js-base64").Base64;
      let code = Base64.encode(JSON.stringify(sheetInfomation));
      let data = {
        code: code,
      };
      const routeData = this.$router.resolve({
        name: "ReplaceThePicture",
        query: data,
      });
      window.open(routeData.href, "_blank");
    },
    adjust(item) {
      // this.indexStudent = ;
      // this.showStudent = true;
      let Base64 = require("js-base64").Base64;
      let data = {
        studentId: item.studentId,
        examId: this.$route.query.examId,
        questionNum: "",
      };
      data.from = "examList";
      let code = Base64.encode(item.name);
      data.code = code;
      Object.assign(data, this.$route.query);
      const routeData = this.$router.resolve({
        name: "MarkStudent",
        query: data,
      });
      window.open(routeData.href, "_blank");
      // this.$router.push({
      //   name: "",
      //   query: data,
      // });
      // this.markabnormolStudentInfo();
    },
    clearSheet() {
      this.delBtnLoading = true;
      if (this.password.length == 0) {
        this.$message({
          type: "warning",
          message: `请输入密码`,
          showClose: true,
        });
        return;
      }
      let userInfo = {
        randomStr: "blockPuzzle",
        code: "xxx",
        password: this.password,
      };
      const user = encryption({
        data: userInfo,
        key: "pigxpigxpigxpigx",
        param: ["password"],
      });
      let data = {
        password: user.password,
        type: this.scanType,
        deleteList: this.checkSheet,
        examPaperId: this.$route.query.examPaperId,
      };
      deleteScanrecordAll(data)
        .then(() => {
          this.$message({
            type: "success",
            message: `清除成功！`,
            showClose: true,
          });
          // this.tabLoading = true;
          this.delBtnLoading = false;
          this.clearPassword = false;
          this.$emit("scanrecordbycondition");
        })
        .catch(() => {
          this.delBtnLoading = false;
        });
    },
    showClearPass() {
      if (this.checkSheet.length == 0) {
        this.$message({
          type: "warning",
          message: `请选择需要清空的${
            this.scanType == 1 ? "班级" : this.scanType == 2 ? "批次" : "考场"
          }`,
          showClose: true,
        });
        return;
      }
      this.password = "";
      this.clearPassword = true;
      this.showdelTsModel = false;
    },
    checkBtn(type) {
      if (type == 1) {
        let arr = [];
        this.scanMessageList.map((index) => {
          if (this.scanType == 1) {
            arr.push(index.classNum);
          }
          if (this.scanType == 2) {
            let pcDeviceId = index.deviceId
              ? index.deviceId + "-" + index.batch
              : "" + (index.deviceId ? "-" : "") + index.batch;
            arr.push(pcDeviceId);
          }
          if (this.scanType == 3) {
            arr.push(index.studentExamRoom);
          }
        });
        this.checkSheet = arr;
      }
      if (type == 2) {
        let arr = [];
        this.scanMessageList.map((index) => {
          if (this.scanType == 1) {
            if (this.checkSheet.indexOf(index.classNum) == -1) {
              arr.push(index.classNum);
            }
          }
          if (this.scanType == 2) {
            let pcDeviceId = index.deviceId
              ? index.deviceId + "-" + index.batch
              : "" + (index.deviceId ? "-" : "") + index.batch;
            if (this.checkSheet.indexOf(pcDeviceId) == -1) {
              arr.push(pcDeviceId);
            }
          }
          if (this.scanType == 3) {
            if (this.checkSheet.indexOf(index.studentExamRoom) == -1) {
              arr.push(index.studentExamRoom);
            }
          }
        });
        this.checkSheet = arr;
      }
      if (type == 3) {
        this.checkSheet = [];
        return;
      }
    },
    showClear() {
      this.showdelTsModel = true;
      this.checkSheet = [];
      // console.log(this.scanMessageList);
      // console.log(this.indexScan);
      // console.log(this.scanType);
    },
    setErrList(type) {
      // errTypeList
      // errChange
      if (type == 1) {
        this.errChange = this.errTypeList.map((item) => item.value);
      }
      if (type == 2) {
        let arr = this.errTypeList.map((item) => item.value);
        let newArr = [];
        arr.map((index) => {
          if (this.errChange.indexOf(index) == -1) {
            newArr.push(index);
          }
        });
        this.errChange = newArr;
      }
      if (type == 3) {
        this.errChange = [];
      }
    },
    loadView() {
      if (this.scanType == 1) {
        if (this.$route.query.examType == 1) {
          this.scanrecordschool();
        } else {
          this.getScanrecordclass();
        }
      }
      if (this.scanType == 2) {
        this.scanrecordbatch();
      }
      if (this.scanType == 3) {
        this.scanrecordexamroom();
      }
      this.setbatchList();
      clearTimeout(this.getInfo);
      this.scanrecordAnswersheet();
    },
    gobackSheet(row) {
      let data = {
        scanRecordId: row.scanRecordId,
        // absent: type,
      };
      const h = this.$createElement;
      this.$msgbox({
        title: "温馨提示",
        message: h("p", null, [
          h("span", null, "此操作将 "),
          h("span", { style: "color: #f56c6c" }, `${row.name}`),
          h("span", null, " 答题卡退回，并且删除阅卷记录，打分信息等。"),
          h("span", null, "是否继续?"),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.$msgbox.close();
          this.scanrecordBack(data); // 关闭messageBox弹窗
        })
        .catch(() => {
          // 关闭messageBox弹窗
          this.$msgbox.close();
        });
    },
    // 退回答题卡
    scanrecordBack(data) {
      scanrecordBack(data)
        .then(() => {
          this.$message({
            type: "success",
            message: "答题卡退回成功!",
            showClose: true,
          });
          this.$msgbox.close();
          this.currentPage();
        })
        .catch(() => {
          this.$msgbox.close();
        });
    },
    setMiss(type, row) {
      let data = {
        scanRecordId: row.scanRecordId,
        absent: type,
      };
      const h = this.$createElement;
      this.$msgbox({
        title: "温馨提示",
        message: h("p", null, [
          h("span", null, "此操作将 "),
          h("span", { style: "color: #f56c6c" }, `${row.name}`),
          h("span", null, "  设为"),
          h(
            "span",
            { style: "color:  #f56c6c" },
            `${type ? "缺考" : "非缺考"}，将会删除考生阅卷记录，分数等信息`
          ),
          h("span", null, "是否继续?"),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.scanrecordAbsent(data);
        })
        .catch(() => {});
    },
    scanrecordAbsent(data) {
      scanrecordAbsent(data)
        .then(() => {
          this.$message({
            type: "success",
            message: "设置成功!",
            showClose: true,
          });
          this.currentPage();
        })
        .catch(() => {});
    },
    identifyagainBatch(array) {
      identifyagainBatch(array).then(() => {
        this.$message({
          type: "success",
          message: "重新识别成功!",
          showClose: true,
        });
        this.scanrecordbatch();
      });
    },
    setParameter() {
      this.$refs.IdentificationParameter.init();
    },
    checkSheetStatus(type) {
      this.$refs.reidentificationSheet.init(type);
    },
    reidentification() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          message: "请选择需要重新识别的试卷",
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.$confirm(
        `此操作将重新识别共${this.multipleSelection.length}张试卷, 是否继续?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          let array = [];
          this.multipleSelection.map((item) => {
            array.push(item.scanRecordId);
          });
          this.identifyagainBatch(array);
          // this.$emit("scanrecordbycondition", 2);
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    downloadScanrecord() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
        type: this.scanType,
        kind: this.scanState,
        classOrRoom:
          this.scanType == 1
            ? this.indexScan.classNum
            : this.indexScan.studentExamRoom,
      };
      // classOrRoom kind

      this.btnLoading = true;
      downloadScanrecord(data)
        .then((res) => {
          let { url, name } = res.data.data;
          fileDownloadByUrl(url, name);
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    // 批量删除
    deleteScanrecord() {
      let Array = [];

      this.multipleSelection.forEach((item) => {
        Array.push(item.scanRecordId);
      });
      if (this.password.length == 0 && this.multipleSelection.length > 1) {
        this.$message({
          message: "请输入登录密码",
          type: "warning",
          showClose: true,
        });
        return;
      }
      let userInfo = {
        randomStr: "blockPuzzle",
        code: "xxx",
        password: this.password,
      };
      const user = encryption({
        data: userInfo,
        key: "pigxpigxpigxpigx",
        param: ["password"],
      });
      let data = {
        password: user.password,
        ids: Array,
      };
      this.delBtnLoading = true;
      deleteScanrecord(data)
        .then(() => {
          this.delBtnLoading = false;
          this.delPassword = false;
          this.$message({
            type: "success",
            message: "删除成功!",
            showClose: true,
          });
          this.$emit("scanrecordbycondition", 2);
          this.searchList();
        })
        .catch(() => {
          this.delBtnLoading = false;
        });
    },
    setbatchList() {
      this.typeList = [];
      this.scanMessageList.map((item) => {
        if (this.scanType == 2) {
          this.typeChange = "全部批次";
          let deviceId = "";
          if (item.deviceId != null) {
            deviceId = item.deviceId + "-";
          }
          this.typeList.push({
            lable: deviceId + item.batch,
            value: deviceId + item.batch,
            batch: item.batch,
            deviceId: item.deviceId,
          });
        }
        if (this.scanType == 3) {
          this.typeChange = "全部考场";
          this.typeList.push({
            lable: item.studentExamRoom,
            value: item.studentExamRoom,
          });
        }
        // studentExamRoom
      });
    },
    // 过滤方法
    filterChange() {
      this.searchList();
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.currentPage();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.currentPage();
    },
    handleSelectionChange(val) {
      // console.log(val);
      this.multipleSelection = val;
    },
    monitorBack() {
      this.deleteScanrecord();
    },
    delList() {
      // console.log(this.multipleSelection);
      if (this.multipleSelection.length == 0) {
        this.$message({
          message: "请选择需要删除的试卷",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (this.multipleSelection.length == 1) {
        this.$confirm(
          `此操作将永久删除当前选中试卷,并且会清空选中考生成绩, 是否继续?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.deleteScanrecord();
            this.$emit("scanrecordbycondition", 2);
          })
          .catch(() => {
            // this.$message({
            //   type: "info",
            //   message: "已取消删除",
            // });
          });
        return;
      }

      this.password = "";
      this.delPassword = true;

      // this.multipleSelection = [];
    },
    // 批量设为无卡
    setAll(type) {
      // console.log(this.multipleSelection);
      if (
        this.multipleSelection.filter((item) => item.absent != type).length == 0
      ) {
        this.$message({
          message: "请选择需要设置的的考生",
          type: "warning",
        });
        return;
      }
      this.$confirm(
        `此操作将共${this.multipleSelection.length}位考生设为无卡, 是否继续?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          let array = [];
          this.multipleSelection.map((item) => {
            if (item.absent == type) {
              return;
            }
            let data = {
              absent: type,
              studentId: item.studentId,
              examPaperId: this.$route.query.examPaperId,
              userName: this.userInfo.username,
            };
            if (!type) {
              data.id = item.id;
            }
            array.push(data);
          });
          this.approvalAbsent(array, type);
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    // 设置无卡
    setAbsent(item, type) {
      // console.log(item, type);
      let data = {
        absent: type,
        studentId: item.studentId,
        examPaperId: this.$route.query.examPaperId,
        userName: this.userInfo.username,
      };
      if (!type) {
        data.id = item.id;
      }
      let list = [data];
      this.approvalAbsent(list, type);
      // console.log(data);
    },
    approvalAbsent(list, type) {
      approvalAbsent(list).then(() => {
        if (type == 1) {
          this.$message({
            type: "success",
            message: "已设为无卡!",
          });
        } else {
          this.$message({
            type: "success",
            message: "已取消无卡!",
          });
        }
        this.multipleSelection = [];
        this.$emit("getNewScanNum");
        this.searchOldList();
        this.scanrecordAnswersheet();
      });
    },
    // 查看原卷
    showPaper(val) {
      // console.log(val);
      if (!val.url) {
        this.$message({
          message: "当前学生试卷信息为空",
          type: "warning",
        });
        return;
      }
      this.imgUrl = val.url.split("，");
      // this.dialogVisible = true;
      this.$refs.showImgList.showImg = true;
    },
    checkBatchList() {
      // if (this.indexScan.batch) {
      //   this.batchChangeList = [];
      //   this.batchChangeList.push(this.indexScan.batch);
      // }
      this.searchList(1);
    },
    currentPage() {
      if (this.scanType == 1) {
        if (this.$route.query.examType == 1) {
          this.scanrecordschool();
        } else {
          this.getScanrecordclass();
        }
      }
      if (this.scanType == 2) {
        this.scanrecordbatch();
      }
      if (this.scanType == 3) {
        this.scanrecordexamroom();
      }
    },
    // 搜索
    searchList(val) {
      this.tabKey = new Date().getTime();
      this.pageIndex = 1;
      if (this.scanType == 1) {
        if (this.$route.query.examType == 1) {
          this.scanrecordschool();
        } else {
          this.getScanrecordclass();
        }
      }
      if (this.scanType == 2) {
        this.scanrecordbatch(val);
      }
      if (this.scanType == 3) {
        this.scanrecordexamroom(val);
      }
      // this.scanrecordAnswersheet();
    },
    // 搜索
    searchOldList() {
      if (this.tabLoading) {
        return;
      }
      this.tabKey = new Date().getTime();
      // this.pageIndex = 1;
      if (this.scanType == 1) {
        if (this.$route.query.examType == 1) {
          this.scanrecordschool();
        } else {
          this.getScanrecordclass();
        }
      }
      if (this.scanType == 2) {
        this.scanrecordbatch(1);
      }
      if (this.scanType == 3) {
        this.scanrecordexamroom(1);
      }
      // this.scanrecordAnswersheet();
    },
    scanrecordAnswersheet() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
      };
      if (
        this.$route.name != "unionscanSheet" &&
        this.$route.name != "scanSheet"
      ) {
        return;
      }
      // console.log("--------------", this.indexViewType);
      if (this.indexViewType == 1) {
        return;
      }
      scanrecordAnswersheet(data)
        .then((res) => {
          this.scanrecordData = res.data.data;
          // this.getInfo = setTimeout(() => {
          //   this.scanrecordAnswersheet();
          // }, 10000);
        })
        .catch(() => {
          // clearInterval(this.getData);
        });
    },
    scanrecordexamroom() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
        errType: this.errChange.join(","),
        current: this.pageIndex,
        size: this.pageSize,
        keyword: this.keyword,
        type: this.scanState,
        // studentExamRoom: this.typeChange
        //   ? this.typeChange
        //   : this.indexScan.studentExamRoom,
      };
      // if (val) {
      // console.log(this.indexScan);
      data.studentExamRoom = this.indexScan.studentExamRoom;
      // } else {
      //   data.studentExamRoom = this.typeChange;
      // }
      if (
        data.studentExamRoom == "全部考场" ||
        data.studentExamRoom == "全部"
      ) {
        data.studentExamRoom = "";
      }
      this.tabLoading = true;
      scanrecordexamroom(data)
        .then((res) => {
          if (data.type != this.scanState) return;
          this.totalPage = res.data.data.total;
          this.tabLoading = false;
          this.roomScanList = res.data.data.records;
          // console.log(res);
        })
        .catch(() => {
          this.tabLoading = false;
        });
    },
    // 按批次查看
    scanrecordbatch() {
      //
      let data = {
        examPaperId: this.$route.query.examPaperId,
        errType: this.errChange.join(","),
        // batch: this.typeChange ? this.typeChange : this.indexScan.batch,
        current: this.pageIndex,
        size: this.pageSize,
        keyword: this.keyword,
      };
      // console.log(this.indexScan);
      // if (val) {
      data.batch = this.indexScan.batch;
      data.deviceId = this.indexScan.deviceId;
      // } else {
      //   data.batch = this.typeChange;
      // }
      // console.log(this.indexScan);
      // console.log(this.typeChange);
      // console.log(data);
      if (data.batch == "全部批次" || data.batch == "全部") {
        data.batch = "";
      }
      this.tabLoading = true;
      scanrecordbatch(data)
        .then((res) => {
          this.tabLoading = false;
          this.totalPage = res.data.data.total;
          this.batchScanList = res.data.data.records;
        })
        .catch(() => {
          this.tabLoading = false;
        });
    },
    scanrecordschool() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
        type: this.scanState,
        schoolId: this.indexScan.schoolId,
        current: this.pageIndex,
        size: this.pageSize,
        keyword: this.keyword,
      };
      // console.log(this.indexScan);
      if (this.indexScan.schoolName == "全部") {
        data.schoolId = "";
      }
      this.tabLoading = true;
      scanrecordschool(data)
        .then((res) => {
          this.totalPage = res.data.data.total;
          this.studentScanList = res.data.data.records;
          this.tabLoading = false;
        })
        .catch(() => {
          this.tabLoading = false;
        });
    },
    // 按班级查看
    getScanrecordclass() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
        type: this.scanState,
        classNum: this.indexScan.classNum,
        current: this.pageIndex,
        size: this.pageSize,
        keyword: this.keyword,
      };
      // console.log(this.indexScan);
      if (this.indexScan.classNum == "全部") {
        data.classNum = "";
      }
      this.tabLoading = true;
      getScanrecordclass(data)
        .then((res) => {
          if (data.type != this.scanState) return;
          this.totalPage = res.data.data.total;
          this.studentScanList = res.data.data.records;
          this.tabLoading = false;
        })
        .catch(() => {
          this.tabLoading = false;
        });
    },
    checkState(val) {
      this.scanState = val;
      this.searchList();
    },
  },
};
</script>
<style lang="scss">
// .sweepRecord {
.tooltip-btn-list {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  margin-bottom: 0.5rem;
  .el-button + .el-button {
    margin-left: 0.5rem;
  }
}
// }
</style>
<style lang="scss" scoped>
.sweepRecord {
  background-color: #ffffff;
  width: calc(100% - 324px);
  .ts-model {
    .el-checkbox {
      min-width: 130px;
      margin-right: 0;
    }
  }
  .err-tips {
    color: #f56c6c;
  }
  .password {
    margin-top: 12px;
    display: flex;
    align-items: center;
    span {
      flex-shrink: 0;
    }
  }
  .model-img {
    height: calc(100vh - 400px);
    overflow-y: scroll;
    img {
      width: 100%;
    }
  }
  ::v-deep .select-style input {
    border: none;
    text-align: center;
    background-color: #f2f5f8;
  }
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    padding: 16px 0 0;
  }
  .table-box {
    padding: 0 24px 60px;
    .err {
      color: #f56c6c;
    }
  }
  .search-list {
    display: flex;
    justify-content: space-between;
    padding: 24px;
    .tab-list {
      display: flex;
      align-items: center;
      .on {
        background-color: $primary-color;
        color: #ffffff;
        border-color: $primary-color;
      }
      div {
        border: 1px solid #d9d9d9;
        width: 82px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        color: #636363;
        // border-radius: 2px;
        cursor: pointer;
        &:first-child {
          border-right: none;
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
        }
        &:last-child {
          border-left: none;
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
        }
        // &:nth-child(2) {
        //   border-right: none;
        // }
      }
    }
    .input-with-select {
      width: 192px;
      margin-right: 24px;
      .el-input__inner {
        border-right: none;
      }
      ::v-deep .el-input-group__append {
        background-color: #ffffff;
        // border: none;
      }
    }
  }
  .sweep-record-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 22px 0;
    .sweep-record-header-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 15px;
      &:first-child {
        flex-shrink: 0;
      }
      &:last-child {
        flex-wrap: wrap;
        margin-left: 40px;
      }
      div {
        margin-right: 24px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
